import React, { useEffect, useRef } from "react";
import "./css/SidebarStyles.css";
import Dropdown from "react-bootstrap/Dropdown";
import logo from "./img/logo..png";
import { Link, useNavigate } from "react-router-dom";

const Sidebar = ({ isOpen, onCloseSidebar }) => {
  const sidebarRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!sidebarRef.current.contains(event.target)) {
        onCloseSidebar();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onCloseSidebar]);

  const handleDropdownSelect = (eventKey, event) => {
    // Add logic to handle dropdown item selection if needed
    console.log(`Selected dropdown item: ${eventKey}`);
  };

  const handleLinkClick = (path) => {
    navigate(path);
    onCloseSidebar(); // Close the sidebar after navigation
  };

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`} ref={sidebarRef}>
      <div className="row">
        <div className="col-lg-10" style={{marginLeft:'-30px'}}>
          <Link to={"/Admin"}>
            {" "}
            <img className="logoo" width={'100px'} src={logo} alt="" />
          </Link>
        </div>
        <div className="col-lg-2" style={{marginLeft:'30px'}}>
          <button className="close-button" onClick={onCloseSidebar}>
            &#10006;
          </button>
        </div>
      </div>
      <ul className="nav nav-pills flex-column" style={{ marginTop: "100px" }}>
        <Dropdown onSelect={handleDropdownSelect}>
          <Dropdown.Toggle variant="info" id="user-dropdown">
            <i className="bi bi-image"></i> Image Uploads
          </Dropdown.Toggle>
          <Dropdown.Menu className="custom-dropdown-menu">
            <Dropdown.Item eventKey="option1" className="text-center" onClick={() => handleLinkClick("/Admin/PremiumroomGallery")}>
              Premium Gallery
            </Dropdown.Item>
            <Dropdown.Item eventKey="option2" className="text-center" onClick={() => handleLinkClick("/Admin/DeluxeroomGallery")}>
              Deluxe Gallery
            </Dropdown.Item>
            <Dropdown.Item eventKey="option3" className="text-center" onClick={() => handleLinkClick("/Admin/DoubleroomGallery")}>
              Double Gallery
            </Dropdown.Item>
            <Dropdown.Item eventKey="option4" className="text-center" onClick={() => handleLinkClick("/Admin/LuxuryroomGallery")}>
              Luxury Gallery
            </Dropdown.Item>
            <Dropdown.Item eventKey="option5" className="text-center" onClick={() => handleLinkClick("/Admin/SingleroomGallery")}>
              Single Gallery
            </Dropdown.Item>
            <Dropdown.Item eventKey="option6" className="text-center" onClick={() => handleLinkClick("/Admin/SmallroomGallery")}>
              Small Gallery
            </Dropdown.Item>
            <Dropdown.Item eventKey="option7" className="text-center" onClick={() => handleLinkClick("/Admin/CoverimagesGallery")}>
              Rooms Cover Images
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle variant="info" id="dropdown-basic">
            <i className="bi bi-cast"></i> Room Booking
          </Dropdown.Toggle>
          <Dropdown.Menu className="custom-dropdown-menu">
            <Dropdown.Item className="text-center" onClick={() => handleLinkClick("/Admin/BookingForm")}>
              Check Available Rooms
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ul>
    </div>
  );
};

export default Sidebar;