import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header";
import "./admin/css/PremiumImg.css"; // Import the CSS file with your gallery styles
import { BASE_URL } from "./baseUrl";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const ImageGalleryComponent = () => {
  const [images, setImages] = useState([]);
  const [showLightbox, setShowLightbox] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const roomType = "double";
        const response = await axios.get(
          `${BASE_URL}/api/roomimages?roomType=${roomType}`
        );
        const imageUrls = response.data.slice(0, 5).map((imageUrl) => ({
          original: `${BASE_URL}${imageUrl}`,
          thumbnail: `${BASE_URL}${imageUrl}`,
          // description: "Your image title",
        }));
        setImages(imageUrls);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  const handleImageClick = () => {
    setShowLightbox(true);
  };

  const handleQuitButtonClick = () => {
    setShowLightbox(false);
  };

  // // Inline styles for image items in lightbox
  // const lightboxImageStyles = {
  //   width: 'auto', // Adjust the width as needed
  //   height: '400px', // Set a fixed height for all images
  //   objectFit: 'cover', // Maintain aspect ratio and cover entire area
  // };

  return (
    <div style={{ textAlign: "center", overflowX: "hidden" }}>
      <Header />
      {!showLightbox ? (
        <div>
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>Double Room</h2>
              </div>
            </div>
          </div>
          <div className="container" style={{ marginTop: "-35px" }}>
            <div className="row">
              {images.slice(0, 3).map((image, index) => (
                <div key={index} className="col-lg-4">
                  <div
                    className="gallery-item set-bg"
                    style={{
                      backgroundImage: `url(${image.original})`,
                      width: "100%",
                      height: "300px", // Set desired height here
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    onClick={handleImageClick}
                  >
                    <div className="gi-text">
                      <h3>{image.description}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {images.length > 3 && (
              <div className="row">
                {images.slice(3, images.length).map((image, index) => (
                  <div key={index + 3} className="col-lg-6">
                    <div
                      className="gallery-item set-bg"
                      style={{
                        backgroundImage: `url(${image.original})`,
                        width: "100%",
                        height: "300px", // Set desired height here
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                      onClick={handleImageClick}
                    >
                      <div className="gi-text">
                        <h3>{image.description}</h3>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <i
            className="fa fa-close icon"
            style={{ marginLeft: "90%", fontSize: "25px", marginTop: "5px" }}
            onClick={handleQuitButtonClick}
          ></i>
          <ImageGallery 
            items={images} 
            
            styles={{ // Apply styles to the lightbox images
              gallerySlide: { width: 'auto', height: '400px', maxWidth: '90%', margin: 'auto' },
              // Add more styles as needed for other elements
            }} 
            
          />
        </div>
      )}
    </div>
  );
};

export default ImageGalleryComponent;