import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Home from './Home';
import { useNavigate } from "react-router-dom";
  
const Admin = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is logged in
    const checkLoginStatus = () => {
      const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
      setIsLoggedIn(loggedIn);
    };

    checkLoginStatus();
  }, []);

  useEffect(() => {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      navigate("/Admin/Login");
    }
  }, [isLoggedIn, navigate]);



  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div>
      <Navbar onToggleSidebar={handleToggleSidebar} />
      <Sidebar isOpen={isSidebarOpen} onCloseSidebar={handleCloseSidebar} />
      <Home />
    </div>
  );
};

export default Admin;


///////////////////////////////////////////

// import React, { useState, useEffect } from 'react';
// import Navbar from './Navbar';
// import Sidebar from './Sidebar';
// import Home from './Home';
// import { useNavigate } from "react-router-dom";
  
// const Admin = () => {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//   const [isLoggedIn, setIsLoggedIn] = useState(true);

//   const navigate = useNavigate();

//   useEffect(() => {
//     // Check if the user is logged in
//     const checkLoginStatus = () => {
//       const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
//       setIsLoggedIn(loggedIn);
//     };

//     checkLoginStatus();
//   }, []);

//   useEffect(() => {
//     // Redirect to login if not logged in
//     if (!isLoggedIn) {
//       navigate("/Admin/Login");
//     }
//   }, [isLoggedIn, navigate]);

//   const handleToggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };

//   const handleCloseSidebar = () => {
//     setIsSidebarOpen(false);
//   };

//   const homePageStyles = {
//     marginLeft: isSidebarOpen ? '250px' : '0', // Adjust the width of the sidebar as needed
//     transition: 'margin-left 0.5s',
//   };

//   return (
//     <div>
//       <Navbar onToggleSidebar={handleToggleSidebar} />
//       <Sidebar isOpen={isSidebarOpen} onCloseSidebar={handleCloseSidebar} />
//       <div className="home-page" style={homePageStyles}>
//         <Home />
//       </div>
//     </div>
//   );
// };

// export default Admin;
